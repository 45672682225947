<template>
  <div id="winlose-member-report" class="container-fluid">
    <div class="page-header mb-1">
      <strong>แพ้-ชนะ สมาชิก/ประเภท</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>

        <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
          <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
            <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
            <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId, null, true)">{{ breadcrumb.username }}</a>
          </li>
        </ol>

        <b-nav card-header tabs>
          <b-nav-item @click="activeTab='ByMember'" :active="activeTab==='ByMember'">แยกตามสมาชิก</b-nav-item>
          <b-nav-item @click="activeTab='ByType'" :active="activeTab==='ByType'">แยกตามประเภท</b-nav-item>
        </b-nav>
      </b-card-header>

      <WinLoseReportByMember
        v-if="activeTab==='ByMember'"
        :accountId="accountId"
        :reloadCode="byMemberReloadCode"
        @Breadcrumbs="setBreadcrumbs"
        @accountId="toggleChildAccount"
        @data="setData" />

      <WinLoseReportByType
        v-if="activeTab==='ByType'"
        :accountId="accountId"
        :memberAccountId="memberAccountId"
        @Breadcrumbs="setBreadcrumbs"
        @data="setData" />
    </b-card>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import WinLoseReportByMember from './components/WinLoseReportByMember'
import WinLoseReportByType from './components/WinLoseReportByType'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'RoundMemberReport',
  components: {
    WinLoseReportByMember,
    WinLoseReportByType
  },
  data() {
    return {
      activeTab: 'ByMember',
      data: null,
      accountId: this.$store.state.account._id,
      memberAccountId: null,
      Breadcrumbs: [],
      byMemberReloadCode: null
    }
  },
  computed: {
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    }
  },
  methods: {
    setData(data) {
      this.data = data
    },
    toggleChildAccount(accountId, memberAccountId=null, forceReload=false) {
      console.log(accountId, memberAccountId)
      this.accountId = accountId
      this.memberAccountId = memberAccountId

      if(forceReload)
        this.byMemberReloadCode = cryptoRandomString({length: 10})
    },
    setBreadcrumbs(breadcrumbs) {
      this.Breadcrumbs = breadcrumbs
    }
  },
  mounted() {
    this.accountId = this.$store.state.account._id
  }
}
</script>
